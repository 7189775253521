<template>
  <div class="all-container">
    <div class="logo-container">
      <el-avatar :size="logoHeight" shape="square" :src="logo" />
      <h1 class="logo-title" v-if="$store.getters.sidebarOpened">
        LetSence<br />乐见未来
      </h1>
    </div>
    <el-scrollbar>
      <sidebar-menu></sidebar-menu>
    </el-scrollbar>
    <!-- 汉堡 -->
    <hamburger class="hamburger-container" />
  </div>
</template>

<script setup>
import SidebarMenu from './SidebarMenu';
import Hamburger from '@/components/Hamburger';
import logo from '@/assets/logo.png'; // 使用 import 导入本地图片
import {} from 'vue';
const logoHeight = 44;
</script>

<style lang="scss" scoped>
.all-container {
  height: 100%;
  width: 100%;
  background-color: #304156;
  overflow: hidden;
  position: relative; // 添加相对定位
  display: flex; // 添加flex布局
  flex-direction: column; // 设置垂直方向排列
}
.logo-container {
  // 使用js定义的变量
  height: v-bind(logoHeight) + 'px';
  padding: 10px 0 22px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .logo-title {
    margin-left: 10px;
    color: #fff;
    font-weight: 600;
    line-height: 20px;
    font-size: 16px;
    white-space: nowrap;
  }
}
.hamburger-container {
  line-height: 45px;
  height: 45px; // 设置具体高度
  width: 100%; // 设置宽度100%
  cursor: pointer;
  transition: background 0.5s;
  display: flex; // 添加flex布局
  align-items: center; // 垂直居中
  padding-left: 16px; // 添加左侧内边距
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}
:deep(.el-menu) {
  border-right: none;

  &.el-menu--collapse {
    .el-sub-menu__title,
    .el-menu-item {
      text-align: center;
      padding: 0 !important;
      justify-content: center;

      .el-icon {
        margin: 0;
      }

      .el-sub-menu__icon-arrow {
        display: none;
      }
    }

    .el-sub-menu > .el-sub-menu__title > span {
      display: none;
    }

    .svg-icon,
    .el-icon {
      margin: 0 !important;
    }
  }

  .el-menu-item,
  .el-sub-menu__title {
    .svg-icon,
    .el-icon {
      margin-right: 16px;
    }
  }
}
</style>
