import store from '@/store';
import axios from 'axios';
import { ElMessage } from 'element-plus';
import { isTokenTimeout } from './auth';

/**
 * 根据根目录的文件名找到对应的配置
 * .env.dev
 * .env.pro
 */

console.log('Base API URL:', process.env); // 添加此行检查

const service = axios.create({
  baseURL: '/api',
  // baseURL: 'http://192.168.1.77:6101',
  // baseURL: 'http://192.168.60.33:6101',
  timeout: 5000,
});

/**
 * 配置请求前的拦截器
 */
service.interceptors.request.use(
  (config) => {
    //统一携带token
    if (store.getters.token) {
      //判断token是否本地过期
      if (isTokenTimeout()) {
        //退出登录
        store.dispatch('user/logout');
        return Promise.reject(new Error('token 失效'));
      }
      //与后端网关约定的头
      config.headers.headerUserToken = store.getters.token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * 配置响应的拦截器
 * 针对后端返回的统一格式
 * {"status":500,"msg":"账号或密码错误","success":false,"data":null}
 */
service.interceptors.response.use(
  (response) => {
    const { status, msg, success, data } = response.data;
    //判断当前请求是否成功： 数据层的
    if (success) {
      //请求成功返回解析后的数据
      return data;
    } else {
      //请求失败进行消息提示: El插件的错误提示
      ElMessage.error(msg);
      return Promise.reject(new Error(msg));
    }
  },
  //网络层的请求失败
  (error) => {
    if (
      error.response &&
      error.response.data &&
      error.response.data.code === 401
    ) {
      //由服务端返回的token过期
      store.dispatch('user/logout');
    }
    //请求失败进行消息提示: El插件的错误提示
    ElMessage.error(error.message);
    return Promise.reject(error);
  }
);

export default service;
