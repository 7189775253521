import { getItem, setItem } from '@/utils/storage';
import { LANG, TAGS_VIEW } from '@/constant';

export default {
  namespaced: true,
  state: () => ({
    sidebarOpened: true,
    language: getItem(LANG) || 'zh',
    /**
     * 需要在layout/componenets/appmain中引用
     */
    tagsViewList: getItem(TAGS_VIEW) || [],
  }),
  mutations: {
    triggerSidebarOpened(state) {
      state.sidebarOpened = !state.sidebarOpened;
    },
    // 添加tag
    addTagsViewList(state, tag) {
      if (!state.tagsViewList.find((item) => item.path === tag.path)) {
        state.tagsViewList.push(tag);
        setItem(TAGS_VIEW, state.tagsViewList);
      }
    },
    /**
     *
     * @param {*} state
     * @param {*} payload : { type: 'other'||'right'||'left'||'index'}
     */
    removeTagsView(state, payload) {
      if (payload.type === 'index') {
        //关闭单个
        state.tagsViewList.splice(payload.index, 1);
      } else if (payload.type === 'other') {
        //关闭其他
        state.tagsViewList.splice(0, payload.index);
        state.tagsViewList.splice(
          payload.index + 1,
          state.tagsViewList.length - payload.index + 1
        );
      } else if (payload.type === 'right') {
        state.tagsViewList.splice(
          payload.index + 1,
          state.tagsViewList.length - payload.index + 1
        );
      } else if (payload.type === 'left') {
        state.tagsViewList.splice(0, payload.index);
      }
      setItem(TAGS_VIEW, state.tagsViewList);
    },
  },
};
