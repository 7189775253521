<template>
  <el-menu
    :collapse="!$store.getters.sidebarOpened"
    :default-active="activeMenu"
    :uniqueOpened="true"
    :background-color="$store.getters.cssVar.menuBg"
    :active-text-color="$store.getters.cssVar.menuActiveText"
    :text-color="$store.getters.cssVar.menuText"
    router
    popper-class="popperClass"
  >
    <sidebar-item v-for="item in routes" :key="item.path" :route="item">
    </sidebar-item>
  </el-menu>
</template>

<script setup>
import { filterRouters, generateMenus } from '@/utils/route';
import SidebarItem from './SidebarItem.vue';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const router = useRouter();
const routes = computed(() => {
  const fRoutes = filterRouters(router.getRoutes());
  return generateMenus(fRoutes);
});

//默认激活项path绑定到default-active: 刷新时能准确找到路径对应的menu为激活
const route = useRoute(); //当前路由
const activeMenu = computed(() => {
  const { path } = route;
  return path;
});
</script>

<style lang="scss" scoped>
.el-icon svg {
  height: 1em;
  width: 1em;
}
</style>
