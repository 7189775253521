<template>
  <div
    class="app-wrapper"
    :class="[$store.getters.sidebarOpened ? 'openSidebar' : 'hideSidebar']"
  >
    <!-- 左侧menu -->
    <sidebar
      id="guide-sidebar"
      class="sidebar-container"
      :style="{ backgroundColor: variables.menuBg }"
    >
    </sidebar>
    <div class="main-container">
      <div class="fixed-header">
        <!-- 顶部navbar -->
        <!-- tags -->
        <div class="left">
          <tags-view id="guide-tags"></tags-view>
          <el-popover
            :teleported="false"
            title="搜索"
            :show-arrow="false"
            placement="bottom-start"
            :width="410"
            trigger="click"
          >
            <template #reference>
              <el-icon class="addSearch">
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="快速打开"
                  placement="bottom"
                  ><Plus
                /></el-tooltip>
              </el-icon>
            </template>
            <el-popover
              v-model="secondPopoverVisible"
              :teleported="false"
              placement="bottom"
              :width="380"
              trigger="click"
              :show-arrow="false"
            >
              <template #reference>
                <el-input
                  class="searchInput"
                  v-model="inputText"
                  placeholder="Please Input"
                  :suffix-icon="Search"
                ></el-input>
              </template>
              <div class="search-content">
                <div class="title">搜索结果</div>
              </div>
            </el-popover>
            <div class="title">常用功能</div>
          </el-popover>
        </div>
        <!-- 右边区域 -->
        <Navbar />
      </div>
      <!-- 内容区 -->
      <app-main />
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, onUnmounted } from 'vue';
import Navbar from './components/Navbar.vue';
import AppMain from './components/AppMain.vue';
import Sidebar from './components/Sidebar';
import TagsView from '@/components/TagsView';
import customPopover from '@/components/Popover';
import { Search } from '@element-plus/icons-vue';
import searchPopover from './components/searchPopover.vue';
// css中定义的变量，在js中使用
import variables from '@/styles/variables.module.scss';

// 平台数据
const pList = reactive([
  {
    id: 1,
    name: '亚马逊',
  },
  {
    id: 2,
    name: '多平台',
  },
]);
// 默认显示的平台
const plantform = ref(pList[0].name);

// 处理平台点击事件
const handlePlantform = (item) => {
  // 修改默认值
  plantform.value = item.name;

  // TODO：处理发送请求和其他逻辑
};

// 定义搜索框的值
const inputText = ref('');
const openSearch = () => {
  console.log('打开搜索');
};

// 控制两个弹出框的显示状态
const firstPopoverVisible = ref(false);
const secondPopoverVisible = ref(false);

// 当第一个弹出框关闭时，也关闭第二个
const handleFirstPopoverClose = () => {
  firstPopoverVisible.value = false;
  secondPopoverVisible.value = false;
};

const handleClickOutside = (e) => {
  const target = e.target;
  if (!target.closest('.el-popover') && !target.closest('.addSearch')) {
    firstPopoverVisible.value = false;
    secondPopoverVisible.value = false;
  }
};

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<style lang="scss" scoped>
@import '~@/styles/mixin.scss';
@import '~@/styles/variables.module.scss';

.app-wrapper {
  // 用到mixin里的方法 clearfix
  @include clearfix;
  position: relative;
  height: 100%;
  width: 100%;
}
.main-container {
  min-width: 2100px;
}
.fixed-header {
  // min-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  white-space: nowrap; /* 可选：用于文本不换行 */
  background-color: #{$menuBg};
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1003;
  // 用到variables里的变量值
  width: calc(100% - #{$sideBarWidth});

  //宽度变化动画
  transition: width #{$sideBarDuration};
  .left {
    .poppover-content {
      white-space: pre-wrap;
    }
  }
}

.hideSidebar .fixed-header {
  width: calc(100% - #{$hideSideBarWidth});
}
// 左侧
.left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 10px;

  .el-icon {
    font-size: 20px;
  }

  .addSearch {
    position: relative;
    cursor: pointer;
    color: #fff;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 24px;
      height: 24px;
      background-color: transparent;
      border-radius: 50%;
      transition: all 0.3s ease;
      z-index: -1;
    }

    &:hover,
    &:focus {
      &::before {
        width: 26px;
        height: 26px;
        background-color: rgba(144, 147, 153, 0.5);
      }
    }

    &:active {
      &::before {
        width: 26px;
        height: 26px;
        background-color: #909399;
      }
    }
  }
}
// 右侧
.right {
  display: flex;
  .help {
    margin: 2px 10px 2px 10px;
  }
}
.el-dropdown-link {
  cursor: pointer;
  color: var(--el-color-primary);
  display: flex;
  align-items: center;
}

// 弹出框搜索
.searchInput {
  width: 380px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 10px 0;
}
.searchInput:hover {
  border: 1px solid #409eff;
}

.search-content {
  padding: 12px;

  .title {
    font-weight: 700;
    font-size: 14px;
    color: #0b1019;
    margin-bottom: 12px;
  }
}

:deep(.el-popover) {
  z-index: 2000; // 确保第一层弹框的层级

  .el-popover {
    z-index: 2001; // 确保第二层弹框在第一层之上
  }
}
</style>
