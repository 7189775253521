import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import Particles from 'particles.vue3';
//初始化app
const app = createApp(App);
app.use(store);
app.use(router);
// 引入粒子库
app.use(Particles);
// 初始化样式表
import '@/styles/index.scss';
//导入鉴权守卫
import '@/permission';
//导入svgIcon图标
import SvgIcons from '@/icons';
app.use(SvgIcons);
//导入 element-plus/icons-vue: 使用时如<el-icon><UploadFilled /></el-icon>
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
// element国际化
import zhCn from 'element-plus/es/locale/lang/zh-cn';
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

//导入element-plus 默认样式和组件
import 'element-plus/dist/index.css';
import ElementPlus from 'element-plus';
app.use(ElementPlus, {
  locale: zhCn,
});
//导入全局属性
import filters from '@/filters';
app.use(filters);

//启动挂载
app.mount('#app');
