<template>
  <div class="app-main">
    <router-view v-slot="{ Component }">
      <transition name="fade-transform" mode="out-in">
        <keep-alive>
          <div :key="route.path">
            <component :is="Component" />
          </div>
        </keep-alive>
      </transition>
    </router-view>
  </div>
</template>

<script setup>
import { watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const store = useStore();
const whiteList = ['/login', '/404', '/401'];

//生成title
const getTitle = (route) => {
  if (route.meta) return route.meta.title;
  const pathArr = route.path.split('/');
  return pathArr[pathArr.length - 1];
};

watch(
  route,
  (to, _from) => {
    //不是所有路由都需要保存
    if (whiteList.includes(to.path)) return;
    const { fullPath, meta, name, params, path, query } = to;
    store.commit('app/addTagsViewList', {
      fullPath,
      meta,
      name,
      params,
      path,
      query,
      title: getTitle(to),
    });
  },
  {
    immediate: true,
  }
);
</script>

<style lang="scss" scoped>
.app-main {
  //浏览器可视区域的高度： 100vh , 50px(navBar) , 40px(tags)
  min-height: calc(100vh - 50px - 40px);
  width: 100%;
  position: relative;
  overflow: hidden;
  min-width: 1600px;
  // TODO: app-main的padding-top为60px
  padding: 60px 20px 20px 20px;
  box-sizing: border-box;
}
</style>
