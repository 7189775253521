<template>
  <div class="right">
    <el-dropdown>
      <span class="el-dropdown-link">
        {{ plantform }}
        <el-icon class="el-icon--right">
          <arrow-down />
        </el-icon>
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item
            @click="handlePlantform(item)"
            v-for="item in pList"
            :key="item.id"
            >{{ item.name }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <el-button class="help" link>帮助</el-button>
    <el-dropdown class="avatar-container" trigger="click">
      <div class="avatar-wrapper">
        <!-- 
            :src="$store.getters.userInfo.avatar" -->
        <el-avatar
          shape="circle"
          :size="24"
          src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
        >
        </el-avatar>
        <!-- <tools style="height: 15px" /> -->
        <span class="user-name"
          >{{ $store.getters.userInfo.name || '默认用户'
          }}<el-icon><ArrowDownBold /></el-icon
        ></span>
      </div>
      <template #dropdown>
        <!-- todo: 用el-dropdown-menu 出错-->
        <el-dropdown-menu class="user-dropdown">
          <router-link to="/">
            <el-dropdown-item>主页</el-dropdown-item>
          </router-link>
          <a target="__blank" href="#">
            <el-dropdown-item>课程主页</el-dropdown-item>
          </a>
          <el-dropdown-item @click="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue';
import { Tools } from '@element-plus/icons-vue';
import { useStore } from 'vuex';

const store = useStore();

const logout = () => {
  store.dispatch('user/logout');
};

// 平台数据
const pList = reactive([
  {
    id: 1,
    name: '亚马逊',
  },
  {
    id: 2,
    name: '多平台',
  },
]);
// 默认显示的平台
const plantform = ref(pList[0].name);

// 处理平台点击事件
const handlePlantform = (item) => {
  // 修改默认值
  plantform.value = item.name;

  // TODO：处理发送请求和其他逻辑
};
</script>

<style lang="scss" scoped>
@import '~@/styles/variables.module.scss';

// 右侧
.right {
  // width: 300px;
  display: flex;
  .help {
    margin: 2px 10px 2px 10px;
  }
  margin: 0 10px;
}
.el-dropdown-link {
  cursor: pointer;
  color: var(--el-color-primary);
  display: flex;
  align-items: center;
}

.avatar-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.avatar-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  .el-avatar {
    margin: 0;
  }
  .user-name {
    margin: 0;
    display: flex;
    align-items: center;
    .el-icon {
      margin-left: 4px;
      vertical-align: middle;
    }
  }
}
</style>
