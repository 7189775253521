import { getUserInfo, login } from '@/api/sys-passport';
import { TOKEN } from '@/constant';
import router from '@/router';
import { setTokenTimeStamp } from '@/utils/auth';
import { getItem, removeAllItem, setItem } from '@/utils/storage';
import md5 from 'md5';

/**
 * vuex的常规写法
 * 理解	this.commit('user/setToken', '')
 * 其他页面调佣使用时：
 * import { useStore } from 'vuex'
 *	const store = useStore()
 *  store.dispatch('user/logout')
 */

export default {
  namespaced: true,
  state: () => ({
    token: getItem(TOKEN) || '',
    userInfo: {},
  }),
  mutations: {
    setToken(state, token) {
      state.token = token;
      setItem(TOKEN, token);
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
  },
  actions: {
    /**
     * 登录
     */
    async login({ commit }, userInfo) {
      try {
        const { username, password } = userInfo;
        // 调用登录接口
        const data = await login({ username, password });
        console.log(data, '登录');

        // 设置token
        commit('setToken', data.token);

        // 保存登录时间，用于设置token过期
        setTokenTimeStamp();

        return data;
      } catch (error) {
        console.error('登录失败:', error);
        throw error; // 向上抛出错误，让调用者处理
      }
    },

    /**
     * 获取用户信息
     */
    async getUserInfo({ commit }) {
      try {
        const res = await getUserInfo();
        commit('setUserInfo', res);
        return res;
      } catch (error) {
        console.error('获取用户信息失败:', error);
        throw error;
      }
    },

    /**
     * 退出登录
     */
    async logout({ commit }) {
      try {
        // 清除token
        commit('setToken', '');
        // 清除用户信息
        commit('setUserInfo', {});
        // 清除所有存储
        removeAllItem();
        // TODO: 清理权限相关设置

        // 跳转到登录页
        await router.push('/login');
      } catch (error) {
        console.error('退出登录失败:', error);
        throw error;
      }
    },
  },
};
