export const TOKEN = 'token';
//token时间戳
export const TOKEN_TIME_STAMP = 'timestamp';
//超时时长
export const TOKEN_TIMEOUT_VALUE = 2 * 3600 * 1000;
//国际化语言
export const LANG = 'language';
//tags
export const TAGS_VIEW = 'tagsView';
// colors 这是饼图的颜色
// 饼图颜色的数组
export const COLORS = [
  '#58c9ff',
  '#42d675',
  '#f9b74a',
  '#ff6b6b',
  '#7b7ddc',
  '#5e7bf7',
  '#e58221',
  '#f9d32c',
  '#8a6bff',
  '#5faaff',
  '#43d776',
  '#fac051',
  '#ff7070',
  '#7e81e0',
  '#607ef8',
  '#e68623',
  '#fad62e',
  '#8e70ff',
  '#50b0ff',
  '#44da77',
  '#fbd558',
  '#ff7676',
  '#8083e4',
  '#6281fa',
  '#e78a25',
  '#fbe02f',
  '#9174ff',
  '#51b1ff',
  '#45db78',
  '#fbe160',
  '#ff7c7c',
  '#8285e8',
  '#6483fb',
  '#e88e27',
  '#fbe430',
  '#9478ff',
];
