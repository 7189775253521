import { createStore } from 'vuex';
import getters from './getters';
import user from './modules/user';
import app from './modules/app';
// 持久化插件
import createPersistedState from 'vuex-persist';
const vuexPersist = new createPersistedState({
  key: 'LetSence-app', // 设置存储的 key，建议根据项目名称设置
  storage: window.localStorage, // 默认使用 localStorage，可以根据需要选择 sessionStorage
  reducer: (state) => ({
    //   需要持久化的 state
    //   user: state.user,
    app: state.app,
  }),
});

export default createStore({
  getters,
  modules: {
    user,
    app,
  },
  plugins: [vuexPersist.plugin],
});
