<template>
  <div class="tags-view-container">
    <el-scrollbar class="tags-view-wrapper">
      <router-link
        class="tags-view-item"
        :class="isActive(tag) ? 'active' : ''"
        :style="{
          backgroundColor: isActive(tag) ? $store.getters.cssVar.menuBg : '',
          borderColor: isActive(tag) ? $store.getters.cssVar.menuBg : '',
        }"
        v-for="(tag, index) in $store.getters.tagsViewList"
        :key="tag.fullPath"
        :to="{ path: tag.fullPath }"
        @contextmenu.prevent="openMenu($event, index)"
      >
        <span>{{ tag.title }}</span>
        <!-- <svg-icon
				    class="close-icon"
					v-show="!isActive(tag)"
					icon="close"
					@click.prevent.stop="onCloseClick(index)"
				></svg-icon> -->
        <el-icon
          class="close-icon"
          v-show="!isActive(tag)"
          @click.prevent.stop="onCloseClick(index)"
          ><CloseBold
        /></el-icon>
      </router-link>
    </el-scrollbar>
    <right-click-menu
      v-show="visible"
      :style="menuStyle"
      :index="selectIndex"
    ></right-click-menu>
  </div>
</template>

<script setup>
import RightClickMenu from './RightClickMenu.vue';
import { ref, reactive, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
const route = useRoute();

/**
 * 是否被选中
 */
const isActive = (tag) => {
  return tag.path === route.path;
};

/**
 * 关闭 tag 的点击事件
 */
const store = useStore();
const onCloseClick = (index) => {
  store.commit('app/removeTagsView', {
    type: 'index',
    index: index,
  });
};

/**
 * 右键点击tag弹出菜单相关
 */
const selectIndex = ref(0);
const visible = ref(false);
const menuStyle = reactive({
  left: 0,
  top: 0,
});
const openMenu = (e, index) => {
  visible.value = true;
  const { x, y } = e;
  menuStyle.left = x + 'px';
  menuStyle.top = y + 'px';
  selectIndex.value = index;
};
const closeMenu = () => {
  visible.value = false;
};

/**
 * 监听变化: 点击body任何区域，关闭右键菜单
 */
watch(visible, (val) => {
  if (val) {
    document.body.addEventListener('click', closeMenu);
  } else {
    document.body.removeEventListener('click', closeMenu);
  }
});
</script>

<style lang="scss" scoped>
.tags-view-wrapper {
  white-space: nowrap;
  position: relative;

  .tags-view-item {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    background: #304156;
    color: #fff;
    padding: 2px 4px;
    font-size: 12px;
    margin-left: 5px;
    margin-top: 4px;
    width: auto;
    min-width: 80px;
    &:first-of-type {
      margin-left: 7px;
    }
    &:last-of-type {
      margin-right: 15px;
    }
    &.active {
      // text-align: center;
      // vertical-align: center;
      color: #304156;
      background-color: #fff !important;
      border-radius: 5px 5px 0 0;
      &::before {
        content: '';
        // background: #fff !important;
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        position: relative;
        margin-right: -4px;
      }
    }
    // close 按钮
    .el-icon {
      margin-left: 2px;
      font-size: 12px;
      width: 12px;
      height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 50%;
        width: 16px;
        height: 16px;
        transition: all 0.3s ease;
      }
    }
    span {
      margin-right: 2px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
    }
  }
}
// }
.tags-view-wrapper:first-child {
  margin-left: -17px;
}
.tags-view-item:hover {
  color: #b4bccc;
}
.tags-view-item::after {
  content: '';
  position: absolute;
  right: -1px;
  top: 5px;
  height: 50%;
  line-height: 13px;
  width: 1px;
  background: #394d66;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.tags-view-item.active::after {
  background: #304156;
}
</style>
